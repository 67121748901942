<template>
  <div class="tree-box">
    <div class="tree-top">
      <div :class="{ 'tree-title': true, active: !activeTreeObj }" @click="clearClick">
        <div class="flex flex-y-center">
          <i class="el-icon-office-building" />
          <span class="m-l-5">金宏旺</span>
        </div>
      </div>
      <div class="flex flex-y-center">
        <el-link class="m-l-5" type="success" @click="folderAddSub">新增</el-link>
        <el-link class="m-l-5" type="primary" @click="toggle">
          {{ isExpandAll ? '收起↑' : '展开↓' }}
        </el-link>
      </div>
    </div>
    <div class="tree-bottom">
      <el-tree
        ref="refTree"
        :data="listData"
        :props="treeProps"
        :expand-on-click-node="false"
        :indent="12"
        node-key="id"
        default-expand-all
        @node-click="itemClick"
      >
        <template slot-scope="{ data }">
          <div v-if="data.is_folder" class="tree-item is-folder">
            <div class="tree-name">
              <span class="tree-overflow" :title="data.name">
                <i class="iconfont icon-folder f-s-12" />
                <span>{{ data.name }}</span>
              </span>
            </div>
            <el-dropdown size="mini" trigger="click">
              <span class="el-dropdown-link">
                <span>操作</span>
                <i class="el-icon-arrow-down m-l-5" />
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item icon="iconfont icon-power" @click.native="folderSetPower(data)"
                  >分配权限
                </el-dropdown-item>
                <el-dropdown-item icon="iconfont icon-user-add" @click.native="fileAdd(data)"
                  >新增员工
                </el-dropdown-item>
                <el-dropdown-item icon="iconfont icon-folder-add" @click.native="folderAddSub(data)"
                  >新增下级部门
                </el-dropdown-item>
                <el-dropdown-item icon="iconfont icon-folder-edit" @click.native="folderEdit(data)"
                  >编辑部门
                </el-dropdown-item>
                <el-dropdown-item
                  icon="iconfont icon-folder-delete"
                  @click.native="folderDelete(data)"
                  >删除部门
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div v-else class="tree-item">
            <div class="tree-name">
              <span class="tree-overflow" :title="fileStr(data)">
                <i class="iconfont icon-user f-s-12" />
                <span>{{ fileStr(data) }}</span>
              </span>
            </div>
            <div v-if="data.is_boss">&nbsp;</div>
            <div v-else>
              <el-dropdown size="mini" trigger="click">
                <span class="el-dropdown-link">
                  <span>操作</span>
                  <i class="el-icon-arrow-down m-l-5" />
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-if="!data.is_leader"
                    icon="iconfont icon-power"
                    @click.native="fileSetPower(data)"
                    >分配权限
                  </el-dropdown-item>
                  <el-dropdown-item
                    v-if="!data.is_leader"
                    icon="iconfont icon-leader-open"
                    @click.native="fileLeaderOpen(data)"
                    >设为负责人
                  </el-dropdown-item>
                  <el-dropdown-item
                    v-if="data.is_leader"
                    icon="iconfont icon-leader-close"
                    @click.native="fileLeaderClose(data)"
                    >取消负责人
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </template>
      </el-tree>
    </div>
    <PopupFolderAdd
      v-if="isShowPopupFolderAdd"
      :show.sync="isShowPopupFolderAdd"
      :detail="activeObj"
      @success="getListData"
    />
    <PopupFolderEdit
      v-if="isShowPopupFolderEdit"
      :show.sync="isShowPopupFolderEdit"
      :detail="activeObj"
      @success="folderEditSuccess"
    />
  </div>
</template>

<script>
import PopupFolderAdd from './popup-folder-add'
import PopupFolderEdit from './popup-folder-edit'

export default {
  components: {
    PopupFolderAdd,
    PopupFolderEdit
  },
  props: {
    activeTreeObj: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      isExpandAll: true,
      listData: [],
      treeProps: {
        label: 'name'
      },
      activeObj: {},
      isShowPopupFolderAdd: false,
      isShowPopupFolderEdit: false
    }
  },
  mounted() {
    this.getListData()
  },
  methods: {
    // 员工字符串
    fileStr(data) {
      let result = ''
      if (data.leader_name) result = `(${data.leader_name})`
      if (data.is_admin) result = '(系统管理员)'
      if (data.is_boss) result = '(超级管理员)'
      result = `${data.name}${result}`
      return result
    },
    // 获取列表数据
    getListData() {
      this.$api({
        method: 'post',
        url: '/admin/gcvip/bumen/list'
      }).then((res) => {
        if (res.data.state === 'ok') {
          this.listData = [...res.data.data]
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    // 切换
    toggle() {
      this.isExpandAll = !this.isExpandAll
      const nodesMap = this.$refs.refTree.store.nodesMap
      for (const key in nodesMap) {
        nodesMap[key].expanded = this.isExpandAll
      }
    },

    // 部门-新增下级
    folderAddSub(data) {
      this.activeObj = this.$util.deepCopy(data)
      this.isShowPopupFolderAdd = true
    },
    // 部门-编辑
    folderEdit(data) {
      this.activeObj = this.$util.deepCopy(data)
      this.isShowPopupFolderEdit = true
    },
    // 部门-编辑成功
    folderEditSuccess() {
      this.$emit('success')
    },
    // 部门-删除
    folderDelete(data) {
      this.$confirm(`部门名称：${data.name}，是否删除该部门及子部门?`, '提示')
        .then(() => {
          this.folderDeleteConfirm(data.id)
        })
        .catch(() => {})
    },
    // 部门-删除-确认
    folderDeleteConfirm(id) {
      this.$api({
        method: 'get',
        url: '/admin/gcvip/bumen/delete',
        params: {
          id
        }
      }).then((res) => {
        if (res.data.state === 'ok') {
          this.$message.success(res.data.msg)
          if (this.activeTreeObj.id === id) this.clearClick()
          this.getListData()
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    // 部门-分配权限
    folderSetPower() {},

    // 员工-新增
    fileAdd(data) {
      this.$emit('fileAdd', this.$util.deepCopy(data))
    },
    // 员工-分配权限
    fileSetPower() {},
    // 员工-设为负责人
    fileLeaderOpen(data) {
      this.$emit('fileLeaderOpen', this.$util.deepCopy(data))
    },
    // 员工-取消负责人
    fileLeaderClose(data) {
      this.$emit('fileLeaderClose', this.$util.deepCopy(data))
    },

    // 项-点击
    itemClick(data) {
      this.$emit('update:activeTreeObj', this.$util.deepCopy(data))
    },
    // 清空-点击
    clearClick() {
      this.$emit('update:activeTreeObj', null)
      this.$refs.refTree.setCurrentKey()
    }
  }
}
</script>

<style lang="less" scoped>
.tree-box {
  color: #606266;
  .tree-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px 5px 5px;
    .tree-title {
      display: flex;
      align-items: center;
      flex: 1;
      padding: 0 5px;
      line-height: 28px;
      cursor: pointer;
      &:hover {
        background: #f5f7fa;
      }
      &.active {
        color: #66b1ff;
        background: #ecf5ff;
      }
    }
  }
  .tree-bottom {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 38px;
    overflow-y: auto;
    border-top: 1px solid #e4e7ed;
    .scroll-bar();
    .tree-item {
      flex: 1;
      margin-right: 10px;
      display: flex;
      justify-content: space-between;
      &.is-folder {
        .tree-name {
          font-weight: bold;
        }
      }
      .tree-name {
        position: relative;
        flex: 1;
        margin-right: 5px;
        .tree-overflow {
          i {
            margin-right: 2px;
          }
          display: flex;
          align-items: center;
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          left: 0;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
</style>
