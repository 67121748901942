<template>
  <el-dialog title="编辑部门" width="500px" :visible.sync="selfShow" :close-on-click-modal="false">
    <div>
      <e-form
        ref="refForm"
        :config="formConfig"
        :column="formColumn"
        :data.sync="selfDetail"
        :rules="rules"
      />
    </div>
    <div slot="footer">
      <el-button size="small" @click="popupCancel">取消</el-button>
      <el-button size="small" type="primary" @click="popupConfirm">提交</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    detail: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      selfShow: false,
      selfDetail: {
        parent_name: '无',
        id: '',
        name: ''
      },
      formConfig: {
        inline: false,
        width: '100%'
      },
      formColumn: [
        {
          type: 'text',
          title: '上级部门',
          field: 'parent_name',
          disabled: true
        },
        {
          type: 'text',
          title: '部门名称',
          field: 'name',
          required: true
        }
      ],
      rules: {
        name: [{ required: true, message: '请输入部门名称' }]
      }
    }
  },
  watch: {
    selfShow(val) {
      this.$emit('update:show', val)
    }
  },
  mounted() {
    this.selfShow = this.show
    let { parent_name, id, name } = this.detail
    if (parent_name) this.selfDetail.parent_name = parent_name
    if (id) this.selfDetail.id = id
    if (name) this.selfDetail.name = name
  },
  methods: {
    // 弹窗取消
    popupCancel() {
      this.selfShow = false
      this.$emit('update:show', false)
    },
    // 弹窗确认
    popupConfirm() {
      this.$refs.refForm.$refs.refForm.validate((valid) => {
        if (valid) {
          this.$api({
            method: 'post',
            url: '/admin/gcvip/bumen/saveOrUpdate',
            data: {
              formData: this.selfDetail
            }
          }).then((res) => {
            if (res.status === 200 && res.data.state === 'ok') {
              this.$message.success('操作成功')
              this.$emit('success')
              this.popupCancel()
            } else {
              this.$message.error(res.data.msg)
            }
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="less" scoped></style>
