<template>
  <div class="page-main">
    <SystemTabs />
    <div class="page-content">
      <!-- v-if="$tool.hasControl(['/admin/gcvip/bumen/saveOrUpdate'])" -->
      <div v-if="isLeader" class="page-left">
        <FolderTree
          ref="refFolderTree"
          :activeTreeObj.sync="activeTreeObj"
          @fileAdd="fileAdd"
          @fileLeaderOpen="fileLeaderOpen"
          @fileLeaderClose="fileLeaderClose"
          @success="updatePageData"
        />
      </div>
      <div :class="{ 'page-right': isLeader }">
        <!-- v-if="$tool.hasControl(['/admin/account/add'])" -->
        <el-form @submit.native.prevent="searchListData">
          <!-- 表格操作条 -->
          <vxe-toolbar
            v-if="isLeader"
            ref="refToolbar"
            size="mini"
            export
            print
            custom
            :loading="loading"
          >
            <template #buttons>
              <el-button type="success" size="mini" @click="fileAdd"> 新增员工 </el-button>
            </template>
            <template #tools>
              <el-input
                style="width: 180px"
                size="mini"
                placeholder="请输入关键字"
                v-model="keyword"
                clearable
              />
              <el-button class="m-l-10" type="primary" size="mini" @click="searchListData">
                搜索
              </el-button>
            </template>
          </vxe-toolbar>
        </el-form>

        <!-- 表格 -->
        <vxe-grid
          ref="refTable"
          size="mini"
          border
          highlight-hover-row
          :loading="loading"
          :height="this.$util.getViewHeight() - 187"
          :export-config="{ type: ['xlsx'], types: ['xlsx'] }"
          :data="tableDataMain"
          :columns="tableColumn"
        >
          <template #default_bumen="{ row }">
            {{ row.bu_men_name.join('>') }}
          </template>
          <template #default_job_name="{ row }">
            {{ row.job_name }}{{ row.leader_name ? `(${row.leader_name})` : '' }}
          </template>
          <template #default_created="{ row }">
            {{ row.created.slice(0, 10) }}
          </template>
          <template #default_updated="{ row }">
            {{ row.updated.slice(0, 10) }}
          </template>
          <template #default_state="{ row }">
            <el-switch
              v-if="!row.is_boss"
              :active-value="1"
              :inactive-value="0"
              v-model="row.state"
              @change="fileSwitch(row, $event)"
            />
          </template>

          <template #default_operate="{ row }">
            <template v-if="!row.is_boss">
              <el-link type="primary" @click="fileEdit(row)">编辑</el-link>
              <template v-if="row.id !== userData.id">
                <el-link
                  v-if="row.is_leader"
                  class="m-l-5"
                  type="info"
                  @click="fileLeaderClose(row)"
                  >取消负责人</el-link
                >
                <template v-if="!row.is_leader">
                  <el-link class="m-l-5" type="primary" @click="fileSetPower(row)"
                    >分配权限</el-link
                  >
                  <el-link class="m-l-5" type="success" @click="fileLeaderOpen(row)"
                    >设为负责人</el-link
                  >
                  <el-link class="m-l-5" type="warning" @click="fileFileMoveFolder(row)"
                    >转移部门</el-link
                  >
                  <el-link class="m-l-5" type="warning" @click="fileFileMoveData(row)"
                    >转移数据</el-link
                  >
                  <el-link class="m-l-5" type="info" @click="fileDelete(row)">删除</el-link>
                </template>
              </template>
            </template>
          </template>
          <template #pager>
            <div></div>
            <vxe-pager v-bind="pageOptions" @page-change="pageChange" />
          </template>
        </vxe-grid>
      </div>
    </div>
    <b-popup-account
      v-if="isShowPopupAccount"
      :show.sync="isShowPopupAccount"
      :activeObj="activeTreeObj"
      :detailId="activeFileId"
      @success="updatePageData"
    />
    <PopupFileMoveFolder
      v-if="isShowPopupFileMoveFolder"
      :show.sync="isShowPopupFileMoveFolder"
      :detail="activeFileObj"
      @success="updatePageData"
    />
    <PopupFileMoveData
      v-if="isShowPopupFileMoveData"
      :show.sync="isShowPopupFileMoveData"
      :detail="activeFileObj"
      @success="updatePageData"
    />
    <PopupLeaderOpen
      v-if="isShowPopupLeaderOpen"
      :show.sync="isShowPopupLeaderOpen"
      :detail="activeFileObj"
      @success="updatePageData"
    />
    <PopupLeaderClose
      v-if="isShowPopupLeaderClose"
      :show.sync="isShowPopupLeaderClose"
      :detail="activeFileObj"
      @success="updatePageData"
    />
  </div>
</template>

<script>
import SystemTabs from '../common/system-tabs'
import FolderTree from './common/folder-tree'
import PopupFileMoveFolder from './common/popup-file-move-folder'
import PopupFileMoveData from './common/popup-file-move-data'
import PopupLeaderOpen from './common/popup-leader-open'
import PopupLeaderClose from './common/popup-leader-close'

export default {
  components: {
    SystemTabs,
    FolderTree,
    PopupFileMoveFolder,
    PopupFileMoveData,
    PopupLeaderOpen,
    PopupLeaderClose
  },
  data() {
    return {
      loading: true,
      keyword: '',
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        },
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              picker.$emit('pick', new Date())
            }
          },
          {
            text: '昨天',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24)
              picker.$emit('pick', date)
            }
          },
          {
            text: '一周前',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', date)
            }
          }
        ]
      },
      tableColumn: [
        { title: 'ID', field: 'id', width: 40 },
        { title: '姓名', field: 'nick_name' },
        { title: '登录账号', field: 'user_name' },
        { title: '部门', field: 'bu_men_name', slots: { default: 'default_bumen' } },
        { title: '岗位名称(新)', field: 'job_name', slots: { default: 'default_job_name' } },
        { title: '岗位权限(旧)', field: 'role_name' },
        { title: '创建日期', field: 'created', width: 80, slots: { default: 'default_created' } },
        { title: '更新日期', field: 'updated', width: 80, slots: { default: 'default_updated' } },
        { title: '提成比例', field: 'commission_rate', width: 60 },
        { title: '可调休时间', field: 'over_date_str', width: 100, visible: false },
        {
          field: 'state',
          title: '启用',
          width: 60,
          slots: {
            default: 'default_state'
          }
        },
        { title: '操作', width: 320, slots: { default: 'default_operate' } }
      ],
      tableDataMain: [],
      pageOptions: {
        ...this.$constant.page,
        pageSize: 100
      },
      activeTreeObj: null,
      activeFileObj: null,
      activeFileId: '',
      isShowPopupAccount: false,
      isShowPopupFileMoveFolder: false,
      isShowPopupFileMoveData: false,
      isShowPopupLeaderOpen: false,
      isShowPopupLeaderClose: false
    }
  },
  computed: {
    userData() {
      let result = this.$store.getters.getLoginAccount
      return result
    },
    isLeader() {
      let result = this.$store.getters.getLoginAccount
      return result.isAdmin || result.is_boss || result.is_admin || result.is_leader
    }
  },
  watch: {
    activeTreeObj() {
      this.getListData()
    }
  },
  mounted() {
    this.initColumn()
    this.getListData()
    this.$nextTick(() => {
      this.$refs.refTable.connect(this.$refs.refToolbar) // 手动将表格和工具栏进行关联
    })
  },
  methods: {
    // 更新页面数据
    updatePageData() {
      this.initColumn()
      this.$refs.refFolderTree.getListData()
      this.getListData()
    },
    // 初始化列
    initColumn() {
      if (this.$tool.hasControl(['/dingding/saveOrUpdate'])) {
        this.tableColumn.forEach((item) => {
          if (item.field === 'over_date_str') {
            item.visible = true
          }
        })
      }
    },
    // 搜索列表数据
    searchListData() {
      this.pageOptions.currentPage = 1
      this.getListData()
    },
    // 获取列表数据
    getListData() {
      this.loading = true
      let filterData = {}
      if (this.activeTreeObj) {
        if (this.activeTreeObj.is_folder) {
          filterData.bu_men_id = this.activeTreeObj.id
        } else {
          filterData.id = this.activeTreeObj.account_id
        }
      }
      this.$api({
        method: 'post',
        url: '/admin/account/getAccountList',
        data: {
          keyword: this.keyword,
          currentPage: this.pageOptions.currentPage,
          pageSize: this.pageOptions.pageSize,
          filterData
        }
      })
        .then((res) => {
          if (res.status === 200) {
            this.tableDataMain = res.data.page.list
            this.pageOptions.pageSize = res.data.page.pageSize
            this.pageOptions.total = res.data.page.totalRow
            this.pageOptions.currentPage = res.data.page.pageNumber
          }
        })
        .catch(() => {})
        .then(() => {
          this.loading = false
        })
    },
    // 员工-分页切换
    pageChange({ currentPage, pageSize }) {
      this.pageOptions.currentPage = currentPage
      this.pageOptions.pageSize = pageSize
      this.getListData()
    },
    // 员工-启用/禁用
    fileSwitch(row, $event) {
      row.state = row.state == 0 ? 1 : 0
      this.$confirm('确认要改变员工状态 : ' + row.nick_name + '?', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          // 发送ajax请求，处理
          this.$api({
            method: 'post',
            url: '/admin/account/switchStateVue',
            params: {
              id: row.id,
              checked: $event == 1 ? true : false
            }
          }).then((res) => {
            if (res.data.state === 'ok') {
              this.$message.success('编辑成功')
              row.state = row.state == 0 ? 1 : 0
            } else {
              this.$message.error(res.data.msg)
            }
          })
        })
        .catch(() => {
          this.$message.info('已取消操作')
        })
    },
    // 员工-新增
    fileAdd() {
      this.activeFileId = ''
      this.isShowPopupAccount = true
    },
    // 员工-编辑
    fileEdit(row) {
      this.activeFileId = row.id
      this.isShowPopupAccount = true
    },
    // 员工-删除
    fileDelete(row) {
      this.$confirm(`删除前请先将数据转移给其他员工，您确认要删除该员工(${row.nick_name})?`, '提示')
        .then(() => {
          this.$api({
            method: 'post',
            url: '/admin/account/deleteVue',
            params: {
              id: row.id
            }
          }).then((res) => {
            if (res.status === 200 && res.data.state === 'ok') {
              this.$message.success('删除成功')
              this.getListData()
            } else {
              this.$message.error(res.data.msg)
            }
          })
        })
        .catch(() => {})
    },
    // 员工-分配权限
    fileSetPower(row) {
      console.log('分配权限', row)
      this.$message.success('分配权限功能开发中')
    },
    // 员工-转移部门
    fileFileMoveFolder(row) {
      this.activeFileObj = row
      this.isShowPopupFileMoveFolder = true
    },
    // 员工-转移数据
    fileFileMoveData(row) {
      this.activeFileObj = row
      this.isShowPopupFileMoveData = true
    },
    // 员工-设为负责人
    fileLeaderOpen(row) {
      this.activeFileObj = row
      this.isShowPopupLeaderOpen = true
    },
    // 员工-取消负责人
    fileLeaderClose(row) {
      this.activeFileObj = row
      this.isShowPopupLeaderClose = true
    }
  }
}
</script>

<style lang="less" scoped>
.page-main {
  display: flex;
  flex-direction: column;
  .page-content {
    height: 100%;
    position: relative;
    .page-left {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 318px;
      border: 1px solid #e4e7ed;
      border-radius: 4px;
    }
    .page-right {
      margin-left: 330px;
    }
  }
}
</style>
