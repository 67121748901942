<template>
  <el-dialog
    title="员工转移数据"
    width="500px"
    :visible.sync="selfShow"
    :close-on-click-modal="false"
  >
    <div>
      <el-form ref="refForm" label-width="130px" :rules="rules" :model="selfDetail">
        <el-form-item label="转移数据员工:">
          <el-input size="mini" v-model="detail.nick_name" disabled />
        </el-form-item>
        <el-form-item label="接收数据员工:" prop="to_account_id">
          <el-cascader
            ref="refBumen"
            style="width: 100%"
            v-model="selfDetail.to_account_id"
            :options="bumenList"
            :props="bumenProps"
            :show-all-levels="false"
            size="mini"
            clearable
            @change="bumenUpdate"
          />
        </el-form-item>
      </el-form>
    </div>
    <div slot="footer">
      <el-button size="small" @click="popupCancel">取消</el-button>
      <el-button size="small" type="primary" @click="popupConfirm">提交</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    detail: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      selfShow: false,
      selfDetail: {
        from_account_id: '',
        to_account_id: []
      },
      bumenList: [],
      bumenProps: { value: 'id', label: 'name' },
      rules: {
        to_account_id: [{ required: true, message: '请选择接收数据员工' }]
      }
    }
  },
  watch: {
    selfShow(val) {
      this.$emit('update:show', val)
    }
  },
  mounted() {
    this.selfShow = this.show
    if (this.detail.id) this.selfDetail.from_account_id = this.detail.id
    this.getBumenList()
  },
  methods: {
    // 获取部门数据列表
    getBumenList() {
      this.$api({
        method: 'post',
        url: '/admin/gcvip/bumen/list',
        data: {}
      }).then((res) => {
        if (res.data.state === 'ok') {
          this.bumenList = res.data.data
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    // 更新部门
    bumenUpdate() {},
    // 弹窗取消
    popupCancel() {
      this.selfShow = false
      this.$emit('update:show', false)
    },
    // 弹窗确认
    popupConfirm() {
      this.$refs.refForm.validate((valid) => {
        if (valid) {
          let len = this.selfDetail.to_account_id.length
          let to_account_id = Number(this.selfDetail.to_account_id[len - 1].slice(5)) // 去除'user_'
          this.$api({
            method: 'post',
            url: '/admin/gcvip/bumen/zhuanYiData',
            data: {
              data: {
                from_account_id: this.selfDetail.from_account_id,
                to_account_id
              }
            }
          }).then((res) => {
            if (res.status === 200 && res.data.state === 'ok') {
              this.$message.success('操作成功')
              this.$emit('success')
              this.popupCancel()
            } else {
              this.$message.error(res.data.msg)
            }
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="less" scoped></style>
