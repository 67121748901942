<template>
  <el-dialog
    title="员工转移部门"
    width="500px"
    :visible.sync="selfShow"
    :close-on-click-modal="false"
  >
    <div>
      <el-form ref="refForm" label-width="130px" :rules="rules" :model="selfDetail">
        <el-form-item label="员工姓名:">
          <el-input size="mini" v-model="detail.nick_name" disabled />
        </el-form-item>
        <el-form-item label="转移部门:" prop="bu_men_id">
          <el-cascader
            ref="refBumen"
            style="width: 100%"
            v-model="selfDetail.bu_men_id"
            :options="bumenList"
            :props="bumenProps"
            :show-all-levels="false"
            size="mini"
            clearable
            @change="bumenUpdate"
          />
        </el-form-item>
      </el-form>
    </div>
    <div slot="footer">
      <el-button size="small" @click="popupCancel">取消</el-button>
      <el-button size="small" type="primary" @click="popupConfirm">提交</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    detail: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      selfShow: false,
      selfDetail: {
        id: '',
        bu_men_id: []
      },
      bumenList: [],
      bumenProps: { value: 'id', label: 'name', checkStrictly: true },
      rules: {
        bu_men_id: [{ required: true, message: '请选择需要转移的部门' }]
      }
    }
  },
  watch: {
    selfShow(val) {
      this.$emit('update:show', val)
    }
  },
  mounted() {
    this.selfShow = this.show
    if (this.detail.id) this.selfDetail.id = this.detail.id
    if (this.detail.bu_men_id) this.selfDetail.bu_men_id = this.detail.bu_men_id
    this.getBumenList()
  },
  methods: {
    // 预处理函数：移除空的 children
    removeEmptyChildren(data) {
      return data.map((item) => {
        // 深拷贝当前节点，避免修改原始数据
        const newItem = { ...item }
        if (Array.isArray(newItem.children)) {
          if (newItem.children.length === 0) {
            delete newItem.children // 删除空 children
          } else {
            newItem.children = this.removeEmptyChildren(newItem.children) // 递归处理子节点
          }
        }
        return newItem
      })
    },
    // 获取部门数据列表
    getBumenList() {
      this.$api({
        method: 'post',
        url: '/admin/gcvip/bumen/list',
        data: {
          search: 'bu_men'
        }
      }).then((res) => {
        if (res.data.state === 'ok') {
          this.bumenList = this.removeEmptyChildren(res.data.data)
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    // 更新部门
    bumenUpdate() {},
    // 弹窗取消
    popupCancel() {
      this.selfShow = false
      this.$emit('update:show', false)
    },
    // 弹窗确认
    popupConfirm() {
      this.$refs.refForm.validate((valid) => {
        if (valid) {
          let len = this.selfDetail.bu_men_id.length
          this.$api({
            method: 'post',
            url: '/admin/gcvip/bumen/zhuanYiBuMen',
            data: {
              data: {
                account_id: this.selfDetail.id,
                bu_men_id_int: this.selfDetail.bu_men_id[len - 1]
              }
            }
          }).then((res) => {
            if (res.status === 200 && res.data.state === 'ok') {
              this.$message.success('操作成功')
              this.$emit('success')
              this.popupCancel()
            } else {
              this.$message.error(res.data.msg)
            }
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="less" scoped></style>
